import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-social',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf
  ],
  templateUrl: './social.component.html',
  styleUrl: './social.component.scss'
})
export class SocialComponent {
  currentUrl: string = '';
  mainUrl = environment.site;
  @Input() type: 'def' | 'img' = 'def'
  @Input() imageUrl = '';

  ngOnInit(): void {
    this.currentUrl = window.location.href;
  }
}
