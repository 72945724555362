import {Component, Input} from '@angular/core';
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  ValidationErrors,
  NgControl, ValidatorFn, AbstractControl
} from "@angular/forms";
import {langError} from "./lang-error";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'ui-input',
  standalone: true,
  imports: [
    NgIf,
    KeyValuePipe,
    NgForOf,
    TranslateModule
  ],
  templateUrl: './ui-input.component.html',
  styleUrl: './ui-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UIInputComponent
    },
    /*{
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: UIInputComponent
    }*/
  ]
})
export class UIInputComponent implements ControlValueAccessor {

  @Input() label: string = '';
  @Input() isRequired: boolean = false;
  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  value: string = '';
  @Input() Mcontrol!: AbstractControl;

  get getFormControlErrors() {
    return this.Mcontrol?.errors || {};
  }
  isErrorCustom = false;
  lang: any = langError;

  errors: ValidationErrors = {};

  initialValidator!: ValidatorFn;
  validators: ValidatorFn[] = [];

  touched = false;

  disabled = false;

  // @ts-ignore
  onChange: (value: string) => void;

  // @ts-ignore
  onTouched: () => void;

  constructor() {
  }

  updateForControl() {
    if (this.Mcontrol) {
      this.errors = this.Mcontrol.errors as ValidationErrors;
    }
  }
  writeValue(value: string) {
    this.value = value;
    this.updateForControl();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
    this.updateForControl();
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
    this.touched = this.Mcontrol.touched;
    this.updateForControl();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = this.Mcontrol.touched;
    }
    this.updateForControl();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  /*validate(control: ''): ValidationErrors | null {
    const quantity = control.value;
    if (quantity <= 0) {
      return {
        mustBePositive: {
          quantity
        }
      };
    }
  }*/

}
